class IconFinderHelper {
  getIconsForActivityBlock(block) {
    const icons = [];

    if (!block) {
      return icons;
    }

    const iconLabelActivityLength = this.getIconLabel(block.minActivityLength, block.maxActivityLength, "-");
    if (iconLabelActivityLength) {
      icons.push({
        label: `${iconLabelActivityLength} min`,
        icon: "icon-clock",
      });
    }

    const iconLabelAttendees = this.getIconLabel(block.minNumberOfAttendees, block.maxNumberOfAttendees, "-");
    if (iconLabelAttendees) {
      icons.push({
        label: iconLabelAttendees,
        icon: "icon-person-man",
      });
    }

    return icons;
  }

  getIconsForConferencePackageBlock(block) {
    const icons = [];

    if (!block) {
      return icons;
    }

    const iconLabelConferenceLength = this.getIconLabel(block.conferenceLength, "", "", " dagar");
    if (iconLabelConferenceLength) {
      icons.push({
        label: iconLabelConferenceLength,
        icon: "icon-calendar",
      });
    }

    const iconLabelAttendees = this.getIconLabel(block.minNumberOfAttendees, block.maxNumberOfAttendees, "-");
    if (iconLabelAttendees) {
      icons.push({
        label: iconLabelAttendees,
        icon: "icon-person-man",
      });
    }

    return icons;
  }

  getIconsForConferenceRoomBlock(block) {
    const icons = [];
    if (!block) {
      return icons;
    }

    const iconLabelRoomSize = this.getIconLabel(block.roomSize, "", "", "m2");
    if (iconLabelRoomSize) {
      icons.push({
        label: iconLabelRoomSize,
        icon: "icon-area",
      });
    }

    if (block.maxNumberOfAttendees) {
      icons.push({
        label: block.maxNumberOfAttendees,
        icon: "icon-person-man",
      });
    }

    return icons;
  }

  getIconsForRoomBlock(block) {
    const icons = [];

    if (!block) {
      return icons;
    }

    const iconLabelBeds = this.getIconLabel(block.beds, block.optionalExtraBeds, "+");
    if (iconLabelBeds) {
      icons.push({
        label: iconLabelBeds,
        icon: "icon-bed",
      });
    }

    const iconLabelGuests = this.getIconLabel(block.minimumNumberOfGuests, block.maximumNumberOfGuests, "-");
    if (iconLabelGuests) {
      icons.push({
        label: iconLabelGuests,
        icon: "icon-person-man",
      });
    }

    return icons;
  }

  getIconsForSpaTreatmentBlock(block) {
    const icons = [];

    if (!block) {
      return icons;
    }

    const iconLabelTreatmentLength = this.getIconLabel(block.minTreatmentLength, block.maxTreatmentLength, "-");
    if (iconLabelTreatmentLength) {
      icons.push({
        label: `${iconLabelTreatmentLength} min`,
        icon: "icon-clock",
      });
    }

    const iconLabelPrice = this.getIconLabel(block.minPrice, block.maxPrice, "-", " kr");
    if (iconLabelPrice) {
      icons.push({
        label: iconLabelPrice,
        icon: "icon-money",
      });
    }

    return icons;
  }

  getIconLabel(str1, str2 = "", separator = "", labelSuffix = "") {
    let concatTo = "";

    if (str1 && str2) {
      str1 += labelSuffix;
      str2 += labelSuffix;
      concatTo = str1 + separator + str2;
    } else if (str1) {
      concatTo = str1 + labelSuffix;
    } else if (str2) {
      concatTo = str2 + labelSuffix;
    }

    return concatTo;
  }
}

const helper = new IconFinderHelper();
export default helper;
